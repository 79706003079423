import { mapMutations } from "vuex";
import enums from "@/enums";
import { DevourService } from "@/services/devour.service";
import _ from "lodash";

export default {
  mixins: [],
  computed: {
    enums() {
      return enums;
    }
  },
  methods: {
    ...mapMutations("snackbar", ["showMessage","closeMessage"]),

    operationWithCheck(res) {
      if (res.error) {
        this.showMessage({
          context: enums.TOAST_TYPE.ERROR,
          text: res.error
        });
        return null;
      }
      else {
        return res;
      }
    },
    makeTitle(resourceTitle,mode,name,role) {
      let title="";
      switch (mode) {
        case enums.FORM_MODE.CREATE:
          title = this.$t("misc.createResource", {
            roleType: this.$t('role_type.'+role) || "-"
          });
          break;
        case enums.FORM_MODE.UPDATE:
          title = this.$t("misc.updateResource", {
            roleType: this.$t('role_type.'+role) || "-",
            resourceName: name || "-"
          });
          break;
        default:
          break;
      }
      return title;
    },
    makeTitleDetails(type,name) {
      return this.$t("misc.detailResource", {
        resourceType: type,
        resourceName: name
      });
    },
    async createOrUpdateHelper(
      mode,
      resName,
      resType,
      payload,
      createdName,
      updatedName,
      scope,
      extra = null
    ) {
      let pcopy = _.cloneDeep(payload);
      try {
        if (mode == enums.FORM_MODE.CREATE) {
          const res = await DevourService.createOrUpdate(resType,payload);
          if (res && res.error) {
            let err = res.error.length>0 ? res.error[0].title : JSON.stringify(res.error);
            this.showMessage({
              context: enums.TOAST_TYPE.ERROR,
              text: "Errore: " + err
            });
            return false;
          }
          if (extra) {
            let extra_res = await extra(res);
            if (extra_res) {
              this.showMessage({
                context: enums.TOAST_TYPE.ERROR,
                text: "Errore: " + JSON.stringify(extra_res)
              });
              return false;
            }
          }
          this.showMessage({
            context: enums.TOAST_TYPE.SUCCESS,
            text:  this.$i18n.t("toasts.created") +
              " " +
              resName +
              " " +
              createdName({ v: res && res.data ? res.data.name + " " + res.data.surname : {}, p: pcopy }) +
              " e " + 
              this.$i18n.t("toasts.create_ok") + 
              " " +
              createdName({ v: res && res.data ? res.data.email : {}, p: pcopy })
          });
          return true;
        } else {
          const res = await DevourService.createOrUpdate(resType,payload);
          if (res && res.error) {
            let err = res.error.length>0 ? res.error[0].title : res.error;
            err = res.error['data.attributes.username'] ? res.error['data.attributes.username'].msg : err;
            this.showMessage({
              context: enums.TOAST_TYPE.ERROR,
              text: "Errore: " + err
            });
            return false;
          }
          if (extra) {
            let extra_res = await extra(res);
            if (extra_res) {
              this.showMessage({
                context: enums.TOAST_TYPE.ERROR,
                text:  "Errore: " + JSON.stringify(extra_res)
              });
              return false;
            }
          } else {
            if (scope=='reset') {
              this.showMessage({
                context: enums.TOAST_TYPE.SUCCESS,
                text: this.$i18n.t("toasts.reset_request")
              });
            } else {
              this.showMessage({
                context: enums.TOAST_TYPE.SUCCESS,
                text: this.$i18n.t("toasts.updated") +
                  " " +
                  resName +
                  " " +
                  res.data.name +
                  " " +
                  res.data.surname
                  //updatedName({ v: res && res.data ? res.data.name + " " + res.data.surname : {}, p: pcopy })
              });
            }
            return true;
          }
        }
      } catch (error) {
        this.showMessage({
          context: enums.TOAST_TYPE.ERROR,
          text: "Errore: " + error
        });        
        return false;
      }
    },
    async deleteConfirm(resName, resType, payload, deletedName) {
      return new Promise((resolve) => {
        this.$confirm({
          message:
            this.$t("confirm.deleteMessage"),
          button: {
            no: this.$t("confirm.no"),
            yes: this.$t("confirm.yes")
          },
          callback: async confirm => {
            if (confirm)
              resolve(await this.deleteHelper(resName, resType, payload, deletedName));
            else resolve(false);
          }
        });
      });
    },
    async deleteHelper(resName, resType, payload, deletedName) {
      let pcopy = _.cloneDeep(payload);
      try {
        const res = await DevourService.delete(resType,payload.id);
        if (res && res.error) {
          let err = res.error.length>0 ? res.error[0].title : res.error;
          this.showMessage({
            context: enums.TOAST_TYPE.ERROR,
            text: "Errore: " + err
          });
          return false;
        }
        this.showMessage({
          context: enums.TOAST_TYPE.SUCCESS,
          text: this.$i18n.t("toasts.deleted") +
            " " +
            resName +
            " " +
            deletedName({ v: res ? res.data : {}, p: pcopy })
        });
        return true;
      } catch (error) {
        this.showMessage({
          context: enums.TOAST_TYPE.ERROR,
          text: "Error: " + error
        });
        return false;
      }
    },
    async uploadHelper(payload,role) {
      let role_type = role == 'user' ? 'Docenti' : 'Scuole';
      try {
        const res = await DevourService.upload(payload,role);
        if (res && res.error) {
          this.showMessage({
            context: enums.TOAST_TYPE.ERROR,
            text: "Errore: Sono supportati solo file .csv"
          });
          return false;
        }
        this.showMessage({
          context: enums.TOAST_TYPE.SUCCESS,
          text: `Analizzati ${res.tot} ${role_type}. Vedi i dettagli nel file scaricato.`
        });
        let reason = role == 'user' ? '(Codice fiscale non valido)' : ' ';
        let data = `Sono stati analizzati ${res.tot} ${role_type}. \r\n\n${role_type} aggiunti al sistema ${res.count_created}. \r\n\n${role_type} già esistenti ${res.count_exist}. \r\n\n${role_type} non aggiunti al sistema ${res.count_not_created} ${reason}: \r\n\n${res.not_created}`
        const blob = new Blob([data], { type: 'text/plain' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Report${role_type}.txt`
        link.click()
        return true;
      } catch (error) {
        this.showMessage({
          context: enums.TOAST_TYPE.ERROR,
          text: "Errore: " + error
        });        
        return false;        
      }
    }
  }
}