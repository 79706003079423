<template>
  <v-app>
    <vue-confirm-dialog></vue-confirm-dialog>
    <v-app-bar
      app
      color="primary"
      dark
      v-if="logged"
    >    
      <div>        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)" v-bind="attrs" v-on="on">
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <span>{{$t('tooltip.menu')}}</span>
        </v-tooltip>
      </div>
      <div class="d-flex align-center">
        <v-btn text @click="goTo()">
          <img :src="require('./assets/logo_ese_netsecurity_tras.png')" width="100" />
          <!--h3>NetSecurity</h3-->
        </v-btn>
      </div>

      <v-spacer></v-spacer>
      <!--LocaleSwitch/-->
      <Navigation/>
    </v-app-bar>
    <div v-if="logged">
      <Drawer/>
    </div>
    <v-main>
      <div class="main-container" fluid>
        <router-view/>
      </div>
      <SnackBar ref="snack" />      
    </v-main>
  </v-app>
</template>

<script>
import SnackBar from "./components/SnackBar";
//import LocaleSwitch from "./components/LocaleSwitch";
import Drawer from "./components/Drawer";
import Navigation from "./Navigation";
import { mapGetters, mapState, mapMutations } from "vuex";
import { TokenService } from "@/services/token.service";

export default {
  name: 'App',

  components: {
    SnackBar,
    Navigation,
    Drawer
  },

  computed: {
    ...mapGetters("status", ["logged"]),
    ...mapState(['drawer']),
    loggedUser() {
      return TokenService.getPayload();
    }
  },

  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),
    goTo() {
      if (this.loggedUser.role == 'user') {
        this.$router.push({ path: '/home' });
      } else {
        this.$router.push({ path: '/users' });
      }
    }
  },

  created() {
    if (!localStorage.locale) localStorage.locale = "it";
    this.$i18n.locale = "it";//localStorage.locale;
    //this.$store.commit('SET_DRAWER', false)
  },

  data: function() {
    return {
    };
  },
};
</script>

<style>
html {
  overflow-y: hidden !important;
}
.v-main {
  height: 0px;
}
.v-main__wrap {
  overflow-y: scroll;
}
</style>
