export default {
  ICONS: {
    LEFT: "mdi-arrow-left-drop-circle-outline",
    RIGHT: "mdi-arrow-right-drop-circle-outline",
    EDIT: "mdi-pencil",
    DELETE: "mdi-delete",
    ADD: "mdi-plus",
    BACK: "mdi-arrow-left",
  },
  TOAST_TYPE: {
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    ERROR: "error"
  },
  FORM_MODE: {
    CREATE: "create",
    UPDATE: "update",
  },
  ROLE_NAME: {
    ADMIN: "admin",
    CUSTOMERREP: "customerRep",
    USER: "user"
  }
};
