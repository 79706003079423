import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from "./i18n";
import "./filters";
import {} from "./devour";

Vue.config.productionTip = false

import abilitiesPlugin from "./plugins/abilitiesPlugin";
Vue.use(abilitiesPlugin);

import FlagIcon from 'vue-flag-icon'
Vue.use(FlagIcon);

import VueConfirmDialog from "vue-confirm-dialog";
Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { email, length, max, min } from "vee-validate/dist/rules";
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import { extend } from 'vee-validate';
extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true,
  message: "Questo campo è obbligatorio"
});
extend("email", {
  ...email,
  message: "Email non valida",
});
extend("length", {
  ...length,
  message: "Length not valid",
});
extend("max", {
  ...max,
  message: "Lunghezza non valida",
});
extend("min", {
  ...min,
  message: "Lunghezza non valida",
});
extend("equal", {
  params: ["firstValue"],
  validate: (value, { firstValue }) => {
    if (value === firstValue) {
      return true;
    }
    return false;
  },
  message:
    "Password e conferma password devono essere uguali"
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
