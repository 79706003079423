<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
        </base-item-group>

        <Item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <div />
    </v-list>

    <template v-slot:append v-if="loggedUser.role=='admin'">
      <Item
        :item="{
          title: $t('navigation.settings'),
          icon: 'mdi-cog-outline',
          to: '/settings',
          name: 'Settings'
        }"
      />
    </template>

  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {mapState } from 'vuex';
  import defineAbilityFor from '../abilities';
  import { TokenService } from "@/services/token.service";
  import Item from "@/components/base/Item"

  export default {
    name: 'Drawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          title: 'user',
          icon: 'mdi-account-multiple',
          to: '/users',
          name: 'Docenti'
        },
        {
          title: 'customerRep',
          icon: 'mdi-account-multiple',
          to: '/schools',
          name: 'Scuole'
        },
        {
          title: 'home',
          icon: 'mdi-home-outline',
          to: '/home',
          name: 'Home'
        }
      ],
    }),

    components: { Item },

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        const itemsArray = this.items.map(this.mapItem).filter((object) => object.enabled)
        return itemsArray;
      },
      profile () {
        return {
          avatar: true,
          title: "Menu",
        }
      },
      loggedUser() {
        return TokenService.getPayload();
      }
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t('navigation.'+item.title),
          enabled: this.allowed(item.name)
        }
      },
      allowed(page) {      
        const ability = defineAbilityFor(TokenService.getPayload());
        if (ability.can("route", page)) {
          return true;
        } else {
          return false;
        }
      }
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
