<!--template>
    <div class="my-container">
    <v-container fluid class="my-toolbar">  
        <v-row align="center">
            <v-col>
                <span class="align-middle text-h1"> Home Page</span>          
            </v-col>
        </v-row>        
        <v-row align="left">
            <v-col>
                <span class="align-middle text-h3">Il tuo account docente risulta attivo.</span><br>          
                <span class="align-middle text-h3">Puoi reimpostare le credenziali di accesso nella pagina del tuo profilo.</span> 
            </v-col>
        </v-row>
    </v-container>
    </div> 
</template-->
<template>
  <v-container
    id="user-home"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
      >
        <MaterialCard
          class="v-card-profile"
          avatar=""
          >
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              {{ this.$t("role_type."+this.loggedUser.role).toUpperCase() }}
            </h6>

            <h4 class="display-2 font-weight-light mb-3 black--text">
                {{ this.makeTitle }}
            </h4>

            <p class="font-weight-light grey--text">
                Il tuo account risulta attivo.
            </p>
            <p class="font-weight-light black--text">
                Puoi reimpostare la tua password nella pagina del profilo <a href="/#/profile">cliccando qui<v-icon class="icon-profile">mdi-account</v-icon></a>
            </p>

            <!--v-btn
              color="success"
              rounded
              class="mr-0"
            >
              Follow
            </v-btn-->
          </v-card-text>
        </MaterialCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MaterialCard from "@/components/base/MaterialCard";
import { DevourService } from "@/services/devour.service";
import { TokenService } from "@/services/token.service";

export default {

    components: {
        MaterialCard
    },

    data: () => ({
        profile: {}
    }),

    methods: {
        async getUserProfile(){
            const devourRes = await DevourService.fetchOne("users","role",this.loggedUser.id);
            this.profile = devourRes;
        }
    },

    computed: {
        loggedUser() {
            return TokenService.getPayload();
        },
        makeTitle() {
            return this.profile.surname + ' ' + this.profile.name;            
        }
    },

    created() {
        this.getUserProfile();
    }
}
</script>

<style scoped>
.container {
  margin-top: 10%;
  width: 50%;
}
.v-card__text {
  margin-top: 20px;
}
.icon-profile {
  margin-top: -10px;
}
</style>