import Vue from "vue";
import JsonApi from "devour-client";
import _ from "lodash";

const jsonApi = new JsonApi({
  apiUrl: `${process.env.VUE_APP_ENDPOINT_API_URI}/private`
});
Vue.$jsonApi = jsonApi;

Object.defineProperty(Vue.prototype, "$jsonApi", {
  get() {
    return jsonApi;
  },
  post() {
    return jsonApi;
  }
});

const errorMiddleware = {
  name: "error-middleware",
  error: payload => {
    const errors = _.get(payload, "response.data.errors", []);
    return errors;
  }
};
Vue.$jsonApi.replaceMiddleware("errors", errorMiddleware);

Vue.$jsonApi.define("customer", {
  name: "",
  mechanographyc_code: "",
  cf: "",
  piva: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

Vue.$jsonApi.define("role", {
  name: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  users: {
    jsonApi: "hasMany",
    type: "user"
  }
});

Vue.$jsonApi.define("user", {
  username: "",
  password: "",
  name: "",
  surname: "",
  cf: "",
  mechanographyc_code: "",
  email: "",
  phone: "",
  activation_token: "",
  password_reset_token: "",
  enabled: false,
  confirm_password: "",
  role_id: "",
  file: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  role: {
    jsonApi: "hasOne",
    type: "role"
  }
});
