import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    icons: {
      iconfont: "mdi"
    },
      options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#4CAF50',
        secondary: '#004700',
        accent: '#f38600',
        info: '#00CAE3',
        error: "#FF895D",
        warning: "#7389AE",
      },
    },
  },
})

export default vuetify;
