var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-toolbar",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"align-left",attrs:{"cols":_vm.withSearch?4:8}},[(_vm.withBack)?_c('v-btn',{staticClass:"mr-4 align-middle",attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.goTo()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.enums.ICONS.BACK))])],1):_vm._e(),(_vm.title)?_c('span',{staticClass:"align-middle text-h4"},[_vm._v(_vm._s(_vm.title))]):_vm._e()],1),(_vm.withSearch)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.searchLabel,"color":"secondary","hide-details":"","clearable":""},on:{"input":function($event){_vm.isTyping = true}},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndUp)?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-n2",attrs:{"elevation":"1","fab":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify ")])]}}],null,false,464019914)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.search')))])])]},proxy:true}:null],null,true),model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1):_vm._e(),_c('v-spacer'),_c('v-col',{staticClass:"align-right",attrs:{"cols":"4"}},[(_vm.withFilter)?_c('v-btn',{class:{ 'colored-button': !_vm.allClear, 'ml-2': true },attrs:{"small":"","outlined":"","color":"secondary"},on:{"click":_vm.onFilter}},[_vm._v(" "+_vm._s(_vm.$t("buttons.filter"))+" ")]):_vm._e(),(_vm.withAdd1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.withAdd1)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","color":"accent"},on:{"click":function($event){return _vm.onAdd('user')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.enums.ICONS.ADD))]),_c('span',[_vm._v(_vm._s(_vm.$t("buttons.create_user")))])],1):_vm._e()]}}],null,false,4064841502)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.add')))])]):_vm._e(),(_vm.withAdd2)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.withAdd2)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","color":"accent"},on:{"click":function($event){return _vm.onAdd('customerRep')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.enums.ICONS.ADD))]),_c('span',[_vm._v(_vm._s(_vm.$t("buttons.create_customerRep")))])],1):_vm._e()]}}],null,false,3288844093)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.add')))])]):_vm._e(),(_vm.withEdit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.withEdit)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","large":"","color":"primary"},on:{"click":_vm.onEdit}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.enums.ICONS.EDIT))])],1):_vm._e()]}}],null,false,1295501738)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.edit')))])]):_vm._e(),(_vm.withDelete)?_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","large":"","color":"#f00"},on:{"click":_vm.onDelete}},[_c('v-icon',[_vm._v(_vm._s(_vm.enums.ICONS.DELETE))])],1):_vm._e(),(_vm.withUpload)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.withUpload)?_c('v-btn',_vm._g(_vm._b({class:{ 'colored-button': !_vm.allClear, 'ml-2': true },attrs:{"small":"","outlined":"","color":"secondary"},on:{"click":_vm.onUpload}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.userRole=="user" ? _vm.$t("buttons.upload_user") : _vm.$t("buttons.upload_customer"))+" ")]):_vm._e()]}}],null,false,3295200945)},[_c('span',[_vm._v(_vm._s(_vm.userRole=="user" ? _vm.$t("tooltip.upload_user") : _vm.$t("tooltip.upload_customer")))])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }