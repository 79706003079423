<template>
  <v-container fluid class="my-toolbar">
    <v-row align="center">
      <v-col :cols="withSearch?4:8" class="align-left">
        <v-btn class="mr-4 align-middle" v-if="withBack" fab small @click="goTo()">
          <v-icon>{{ enums.ICONS.BACK }}</v-icon>
        </v-btn>
        <span class="align-middle text-h4" v-if="title">{{ title }}</span>        
      </v-col>
      <v-col cols="4" v-if="withSearch">
        <v-text-field
          :label="searchLabel"
          color="secondary"
          hide-details
          clearable
          v-model="searchQuery"
          @input="isTyping = true"
        >
          <template
            v-if="$vuetify.breakpoint.mdAndUp"
            v-slot:append-outer
          >
            <v-tooltip slot="append" top>
               <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  class="mt-n2"
                  elevation="1"
                  v-bind="attrs"
                  v-on="on"
                  fab>
                  mdi-magnify
                </v-icon>
              </template>
            <span>{{$t('tooltip.search')}}</span>
          </v-tooltip>
          </template>
        </v-text-field>
        <!--v-text-field
          :label="$t('misc.search')"
          color="secondary"
          v-model="searchQuery"
          @input="isTyping = true"
          clearable
          single-line
        ></v-text-field-->
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="4" class="align-right">        
        <v-btn
          v-if="withFilter"
          @click="onFilter"
          small
          outlined
          color="secondary"
          :class="{ 'colored-button': !allClear, 'ml-2': true }"
        >
          {{ $t("buttons.filter") }}
        </v-btn>        
        <v-tooltip top v-if="withAdd1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="withAdd1"
              @click="onAdd('user')"
              small
              color="accent"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ enums.ICONS.ADD }}</v-icon>
              <span>{{ $t("buttons.create_user") }}</span>
            </v-btn>
          </template>
          <span>{{$t('tooltip.add')}}</span>
        </v-tooltip>
        <v-tooltip top v-if="withAdd2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="withAdd2"
              @click="onAdd('customerRep')"
              small
              color="accent"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ enums.ICONS.ADD }}</v-icon>
              <span>{{ $t("buttons.create_customerRep") }}</span>
            </v-btn>
          </template>
          <span>{{$t('tooltip.add')}}</span>
        </v-tooltip>        
        <v-tooltip top v-if="withEdit">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="withEdit"
              class="ml-2"
              icon
              large
              color="primary"
              @click="onEdit"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ enums.ICONS.EDIT }}</v-icon>
            </v-btn>        
          </template>
          <span>{{$t('tooltip.edit')}}</span>
        </v-tooltip>
        <v-btn
          v-if="withDelete"
          class="ml-2"
          icon
          large
          color="#f00"
          @click="onDelete"
        >
          <v-icon>{{ enums.ICONS.DELETE }}</v-icon>
        </v-btn>
        <v-tooltip top v-if="withUpload">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="withUpload"
              @click="onUpload"
              small
              outlined
              color="secondary"
              :class="{ 'colored-button': !allClear, 'ml-2': true }"
              v-bind="attrs"
              v-on="on"
            >
              {{ userRole=="user" ? $t("buttons.upload_user") : $t("buttons.upload_customer")}}
            </v-btn>        
          </template>
          <span>{{ userRole=="user" ? $t("tooltip.upload_user") : $t("tooltip.upload_customer")}}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import enums from "@/enums";
import _ from "lodash";
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    title: String,
    withSearch: Boolean,
    withAdd1: Boolean,
    withAdd2: Boolean,
    withFilter: Boolean,
    withUpload: Boolean,
    withBack: Boolean,
    withEdit: Boolean,
    withDelete: Boolean,
    allClear: {
      type: Boolean,
      default: true
    },
    userRole: String,
    searchLabel: {
      type: String,
      default: 'Ricerca'
      }
  },
  data() {
    return {
      searchQuery: "",
      isTyping: false,
      mainPage: null
    };
  },
  watch:{
    searchQuery: _.debounce(function() {
      this.isTyping = false;
    }, 700),
    isTyping: function(value) {
      if (!value) {
        this.$emit("onSearch", this.searchQuery);
      }
    }
  },
  computed: {
    ...mapState(['drawer']),
    enums() {
      return enums;
    }
  },
  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),
    goTo() {
      if(this.mainPage && this.mainPage!='/undefined') {
        this.$router.push({ path: this.mainPage });
      }
      this.$emit("onBack");
    },
    onAdd(role) {
      this.$emit("onAdd", role);
    },
    onUpload(){
      this.$emit("onUpload");
    },
    onFilter() {
      this.$emit("onFilter");
    },
    onEdit() {
      this.$emit("onEdit", this.userRole);
    },
    onDelete() {
      this.$emit("onDelete");
    }
  },
  created() {
    let array = this.$route.path.split("/");
    let test = array[array.length - 2].split("#")[0];
    if (!isNaN(test)) {
      array.splice(array.length - 1, 1);
      array.splice(array.length - 1, 1);
    } else {
      array.splice(array.length - 1, 1);
    }
    this.mainPage = "/"+array[array.length - 1];
  }
};
</script>

<style scoped>
.colored-button {
  background-color: var(--v-secondary-lighten5);
}
.my-toolbar {
  min-height: 96px;
}
.my-toolbar .row {
  min-height: 96px;
}
</style>
