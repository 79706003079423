import enums from "@/enums";

export default {
  computed: {
    enums() {
      return enums;
    }
  },
  data() {
    return {
      item: null,
      loading: 0,
      editing_id: null
    }
  },
  async created() {
    this.editing_id=await this.retrieveId();
    await this.fetch();
    this.openUpdate(this.item);
  },
  methods: {
    async fetch() {
      this.loading++;
      let res=this.operationWithCheck(await this.fetchFunc(this.editing_id));
      this.loading--;
      if (res) {
        this.item=res;
      }
    },
    handleCancel() {
      this.item={...this.item};
    }
  },
}