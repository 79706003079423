<template>
  <div class="my-container">
    <v-card>
      <Toolbar
      />
      <UserForm
        v-if="formDialog"
        :mode="mode"
        :selectedItem="item"
        :embedded="true"
        @formCancel="goBack()"
        @formSucceed="fetch(item.id)"
      />
    </v-card>
  </div>
</template>

<script>
import Toolbar from "@/components/Toolbar";
import helper from "@/mixins/helper";
import formDialog from "@/mixins/formDialog";
import singleShared from "@/mixins/singleShared";
import UserForm from "@/components/forms/UserForm";
import { DevourService } from "@/services/devour.service";
import { TokenService } from "@/services/token.service";

import enums from "@/enums";

export default {
  name: 'Profile',

  components: {
    UserForm,
    Toolbar
  },

  mixins: [helper,formDialog,singleShared],

  computed: {
    enums() {
      return enums;
    },
    loggedUser() {
      return TokenService.getPayload();
    }
  },

  methods: {
    fetchFunc(id) {
      return DevourService.fetchOne("users","role",id);
    },
    retrieveId() {
      return this.loggedUser.id;
    },
    goBack() {
      if (this.loggedUser.role === "user") {
        this.$router.push({ path: '/home' });
      } else {
        this.$router.push({ path: '/users' });
      }
    }
  },

  data() {
    return {
      resourceType: this.$t("resource_types.user"),
      resourceTypes: this.$t("resource_types.users"),
      injectOpts: {
        sortBy: ["cf"],
        sortDesc: [false],
      },
    };
  }
};
</script>
