<template>
    <v-container
      id="user-profile"
      fluid
      tag="section"
    >        
      <MaterialCard>
        <template v-slot:heading>
          <div v-if="mode == enums.FORM_MODE.UPDATE && loggedUser.id === Number(form.id)  && loggedUser.role != enums.ROLE_NAME.ADMIN" class="display-2 font-weight-light">
            {{formTitle }}
          </div>
          <div v-else>
            <div class="display-2 font-weight-light">
              {{ formTitle.split(' ')[0] + ' ' +  formTitle.split(' ')[1]}}
            </div>
            <div class="subtitle-1 font-weight-light">
              {{ formTitle.split(' ')[2] | capitalize }}
            </div>
          </div>
        </template>

        <v-form>
          <v-container class="py-0">    
            <v-card-subtitle v-show="loggedUser.id == Number(form.id) && loggedUser.role != enums.ROLE_NAME.ADMIN">
              {{ $t('reset_password.help_message') }} <br><br>
              {{ $t('reset_password.help_message_1') }} <br><br>
              {{ $t('reset_password.help_message_2') }}
            </v-card-subtitle>             
            <ValidationObserver v-slot="{ invalid }">
              <v-card-text >
                <div v-if="mode == enums.FORM_MODE.CREATE">
                  <div v-if="role == enums.ROLE_NAME.USER">
                    <v-row>
                      <v-col>
                        <ValidationProvider name="Nome" immediate rules="required" v-slot="{ errors }">
                          <v-text-field
                            class="required"
                            :label="$t('headers.users.name')"
                            v-model="form.name"
                          ></v-text-field>
                          <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                      <v-col>
                        <ValidationProvider name="Cognome" immediate rules="required" v-slot="{ errors }">
                          <v-text-field
                            class="required"
                            :label="$t('headers.users.surname')"
                            v-model="form.surname"
                          ></v-text-field>
                          <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <ValidationProvider name="Codice Fiscale" immediate rules="required|min:16|max:16" v-slot="{ errors }">
                          <v-text-field
                            class="required"
                            :label="$t('headers.users.cf')"
                            v-model="form.cf"
                          ></v-text-field>
                          <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                      <v-col>
                        <ValidationProvider name="Email" immediate rules="required|email" v-slot="{ errors }">
                          <v-text-field
                            class="required"
                            :label="$t('headers.users.email')"
                            v-model="form.email"
                          ></v-text-field>
                          <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>                    
                      </v-col>
                    </v-row>          
                    <v-row>
                      <v-col cols="6">
                        <ValidationProvider name="Telefono">
                          <v-text-field
                            :label="$t('headers.users.phone')"
                            v-model="form.phone"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <!--v-row>             
                      <v-col>
                        <ValidationProvider name="Username">
                          <v-text-field
                            :label="$t('headers.users.username')"
                            v-model="form.username"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col>
                        <ValidationProvider name="Ruolo" immediate rules="required" v-slot="{ errors }">
                          <FetchAutocomplete
                            class="required"
                            :label="$t('headers.users.role')"
                            v-model="form.role_id"
                            :fetchFunc="roleFunc"
                            itemText="name"
                            itemValue="id"
                            :returnObject="false"
                          ></FetchAutocomplete>
                          <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                    </v-row-->
                  </div>
                  <div v-if="role == enums.ROLE_NAME.CUSTOMERREP">
                    <v-row>
                      <v-col>
                        <ValidationProvider name="Codice fiscale" immediate rules="required|min:11|max:11" v-slot="{ errors }">
                          <v-text-field
                            class="required"
                            :label="$t('headers.users.cf')"
                            v-model="form.cf"
                          ></v-text-field>
                          <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                      <v-col>
                        <ValidationProvider name="Denominazione" immediate rules="required" v-slot="{ errors }">
                          <v-text-field
                            class="required"
                            :label="$t('headers.users.school_name')"
                            v-model="form.name"
                          ></v-text-field>
                          <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <ValidationProvider name="Meccanografico" immediate rules="required|length:10" v-slot="{ errors }">
                          <v-text-field
                            class="required"
                            :label="$t('headers.users.mechanographyc_code')"
                            v-model="mechanographyc_code"
                          ></v-text-field>
                          <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                      <v-col>
                        <ValidationProvider name="Username" immediate rules="required" v-slot="{ errors }">
                          <v-text-field
                            class="required"
                            :label="$t('headers.users.username')"
                            v-model="form.username"
                            readonly
                          ></v-text-field>
                          <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <ValidationProvider name="Email" immediate rules="required|email" v-slot="{ errors }">
                          <v-text-field
                            class="required"
                            :label="$t('headers.users.email')"
                            v-model="form.email"
                            readonly
                          ></v-text-field>
                          <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>       
                      <v-col>
                        <ValidationProvider name="Telefono">
                          <v-text-field
                            :label="$t('headers.users.phone')"
                            v-model="form.phone"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div v-if="mode == enums.FORM_MODE.UPDATE">
                  <div v-if="loggedUser.role == enums.ROLE_NAME.CUSTOMERREP && loggedUser.id !== Number(form.id)">
                    <ValidationProvider name="Email" immediate rules="required|email" v-slot="{ errors }">
                      <v-text-field
                        class="required"
                        :label="$t('headers.users.email')"
                        v-model="form.email"
                      ></v-text-field>
                      <span class="form-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div v-else-if="loggedUser.role == enums.ROLE_NAME.ADMIN">
                    <div v-if="role == enums.ROLE_NAME.USER">
                      <v-row>
                        <v-col>
                          <ValidationProvider name="Nome" immediate rules="required" v-slot="{ errors }">
                            <v-text-field
                              class="required"
                              :label="$t('headers.users.name')"
                              v-model="form.name"
                            ></v-text-field>
                            <span class="form-error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </v-col>
                        <v-col>
                          <ValidationProvider name="Cognome" immediate rules="required" v-slot="{ errors }">
                            <v-text-field
                              class="required"
                              :label="$t('headers.users.surname')"
                              v-model="form.surname"
                            ></v-text-field>
                            <span class="form-error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <ValidationProvider name="Codice Fiscale" immediate rules="required|min:16|max:16" v-slot="{ errors }">
                            <v-text-field
                              class="required"
                              :label="$t('headers.users.cf')"
                              v-model="form.cf"
                              disabled
                            ></v-text-field>
                            <span class="form-error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </v-col>
                        <v-col>
                          <ValidationProvider name="Username">
                            <v-text-field
                              :label="$t('headers.users.username')"
                              v-model="form.username"
                              disabled
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>                        
                      </v-row>
                      <v-row>
                        <v-col>
                          <ValidationProvider name="Email" immediate rules="required|email" v-slot="{ errors }">
                            <v-text-field
                              class="required"
                              :label="$t('headers.users.email')"
                              v-model="form.email"
                            ></v-text-field>
                            <span class="form-error">{{ errors[0] }}</span>
                          </ValidationProvider>                    
                        </v-col>      
                        <v-col>
                          <ValidationProvider name="Telefono">
                            <v-text-field
                              :label="$t('headers.users.phone')"
                              v-model="form.phone"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <ValidationProvider name="Password" immediate>
                            <v-text-field
                              :label="$t('headers.users.password')"
                              v-model="form.password"
                              type="password"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col>
                          <ValidationProvider name="Conferma Password" immediate :rules="{ equal: { firstValue: '@Password' } }" v-slot="{ errors }">
                            <v-text-field
                              :label="$t('headers.users.confirm_password')"
                              v-model="form.confirm_password"
                              type="password"
                            ></v-text-field>
                            <span class="form-error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="role == enums.ROLE_NAME.CUSTOMERREP">
                      <v-row>
                        <v-col>
                          <ValidationProvider name="Codice fiscale" immediate rules="required|min:11|max:16" v-slot="{ errors }">
                            <v-text-field
                              class="required"
                              :label="$t('headers.users.cf')"
                              v-model="form.cf"
                              disabled
                            ></v-text-field>
                            <span class="form-error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </v-col>
                        <v-col>
                          <ValidationProvider name="Denominazione" immediate rules="required" v-slot="{ errors }">
                            <v-text-field
                              class="required"
                              :label="$t('headers.users.school_name')"
                              v-model="form.name"
                            ></v-text-field>
                            <span class="form-error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <ValidationProvider name="Meccanografico" immediate rules="required|length:10" v-slot="{ errors }">
                            <v-text-field
                              class="required"
                              :label="$t('headers.users.mechanographyc_code')"
                              v-model="mechanographyc_code"
                            ></v-text-field>
                            <span class="form-error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </v-col>                
                        <v-col>
                          <ValidationProvider name="Username">
                            <v-text-field
                              :label="$t('headers.users.username')"
                              v-model="form.username"
                              disabled
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>                        
                      </v-row>
                      <v-row>      
                        <v-col>
                          <ValidationProvider name="Email" immediate rules="required|email" v-slot="{ errors }">
                            <v-text-field
                              class="required"
                              :label="$t('headers.users.email')"
                              v-model="form.email"
                              disabled
                            ></v-text-field>
                            <span class="form-error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </v-col>   
                        <v-col>
                          <ValidationProvider name="Telefono">
                            <v-text-field
                              :label="$t('headers.users.phone')"
                              v-model="form.phone"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col> 
                      </v-row>
                      <v-row>
                        <v-col>
                          <ValidationProvider name="Password" immediate>
                            <v-text-field
                              :label="$t('headers.users.password')"
                              v-model="form.password"
                              type="password"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col>
                          <ValidationProvider name="Conferma Password" immediate :rules="{ equal: { firstValue: '@Password' } }" v-slot="{ errors }">
                            <v-text-field
                              :label="$t('headers.users.confirm_password')"
                              v-model="form.confirm_password"
                              type="password"
                            ></v-text-field>
                            <span class="form-error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <div v-else-if="loggedUser.id == Number(form.id)">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          :label="$t('headers.users.email')"
                          v-model="emailToShow"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="right">
                      <v-col cols="11">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="submitToStore('reset')" v-bind="attrs" v-on="on" data-vv-scope="reset">
                              <font color="primary">{{$t('buttons.reset')}}</font>
                            </v-btn>
                            </template>
                          <span>{{$t('tooltip.reset')}}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="1">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="onCancel" v-bind="attrs" v-on="on">
                              <font color="secondary">{{$t('buttons.cancel')}}</font>
                            </v-btn>
                            </template>
                          <span>{{$t('tooltip.cancel_reset')}}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-card-text>
              <FormButtons
                v-show="loggedUser.role == enums.ROLE_NAME.ADMIN || loggedUser.id != Number(form.id)"
                :embedded="embedded"
                @onSave="onSubmit"
                @onCancel="onCancel"
                :disabled="invalid || form.password !== form.confirm_password || (loggedUser.role != enums.ROLE_NAME.ADMIN && loggedUser.id == Number(form.id))"
              />
            </ValidationObserver>
          </v-container>
        </v-form>
      </MaterialCard>      
    </v-container>
</template>

<script>
import enums from "@/enums";
import FormButtons from "@/components/FormButtons";
import MaterialCard from "@/components/base/MaterialCard";
import helper from "@/mixins/helper";
import formDialog from "@/mixins/formDialog";
import formShared from "@/mixins/formShared";
import { DevourService } from "@/services/devour.service";
import { TokenService } from "@/services/token.service";


export default {
  name: "UserForm",
  mixins: [helper,formDialog,formShared],
  data() {
    return {
      resourceType: this.$t("resource_types.user"),
      formTitle: "",
      emailToShow: "",
      mechanographyc_code: '',
      form: {
        id: "",
        username: "",
        name: "",
        surname: "",
        cf: "",
        mechanographyc_code: "",
        email: "",
        phone: "",
        password: "",
        confirm_password: "",
        role_id: 1
      }
    };

  },
  components: { FormButtons, MaterialCard },
  props: {
    mode: {
      default: enums.FORM_MODE.CREATE,
      type: String
    },
    role: {
      default: enums.ROLE_NAME.USER,
      type: String
    }
  },
  watch: {
    mechanographyc_code(val) {
      //if (this.$props.mode == enums.FORM_MODE.CREATE) {
      if (val) {
        this.form.mechanographyc_code = val.toUpperCase();
        this.form.email = val.toLowerCase()+'@istruzione.it';
        this.form.username = val.toLowerCase();
      }
    }
  },
  methods: {
    async submitToStore(scope) {   
      if (this.form.password == "") delete this.form["password"]
      if (this.form.confirm_password == "") delete this.form["confirm_password"]
      await this.createOrUpdateHelper(
        this.$props.mode,
        this.resourceType,
        "user",
        this.form,
        payload => payload.v,
        payload => payload.p,
        scope
      );
    },
    getEmailToShow(item) {
      let str = "";
      if (item && item.email) {
        str = item.email.slice(0,3) + '********@' + item.email.split("@")[1]
      }
      return str;
    },
    setForm() {
      if (this.$props.mode == enums.FORM_MODE.CREATE) {
        this.form.id = "";
        this.form.username = "";
        this.form.name = "";
        this.form.surname = "";
        this.form.cf = "";
        this.form.mechanographyc_code = '';
        this.form.email = "";
        this.form.phone = "";
        this.form.password = "";
        this.form.confirm_password = "";
        this.roleFunc()
      } else {
        this.form.id = this.selectedItem.id;
        this.form.username = this.selectedItem.username;
        this.form.name = this.selectedItem.name;
        this.form.surname = this.selectedItem.surname;
        this.form.cf = this.selectedItem.cf;
        this.form.mechanographyc_code = this.selectedItem.mechanographyc_code;
        this.mechanographyc_code = this.selectedItem.mechanographyc_code;
        this.form.email = this.selectedItem.email;
        this.form.phone = this.selectedItem.phone;
        this.form.password = "";
        this.form.confirm_password = "";
        this.form.role_id = this.selectedItem.role_id;
        this.emailToShow = this.getEmailToShow(this.selectedItem)
      }    

      if(this.$props.mode == enums.FORM_MODE.UPDATE && this.loggedUser.id === Number(this.selectedItem.id) && this.loggedUser.role != enums.ROLE_NAME.ADMIN) {  
        this.formTitle=this.$t("misc.resetAccount");
      } else if(this.$props.mode == enums.FORM_MODE.UPDATE && this.loggedUser.id === Number(this.selectedItem.id) && this.loggedUser.role == enums.ROLE_NAME.ADMIN) {  
        this.formTitle=this.$t("misc.updateProfile");
      } else {
        this.formTitle=this.makeTitle(this.resourceType,this.$props.mode,this.form.cf,this.$props.role);
      }  

    },
    
    async roleFunc() {
      let searchValue = this.$props.role;
      let paginationOpts = {
        sortBy: ['name'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 25
      }
      const res =  await DevourService.fetchAll("roles","users",paginationOpts,searchValue,null);
      if(res &&  res.length > 0) {
        let roles = res[0];
        if(roles && roles.length > 0) {
          this.form.role_id = roles[0].id;
        }
      }
    }
  },
  
  computed: {
    enums() {
      return enums;
    },
    loggedUser() {
      return TokenService.getPayload();
    }
  },
};
</script>

<style>
.v-card__subtitle {
  margin-top: 30px;
}
</style>
