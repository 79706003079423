import { AbilityBuilder } from '@casl/ability';

export default function defineAbilityFor(user) {
  return AbilityBuilder.define((can) => {
    if (user.role === 'admin') {
      can('route', 'Scuole')
      can('route', 'Settings')      
    }
    if (user.role === 'admin' || user.role === 'customerRep') {
      can('route', 'Users');
      can('route', 'Docenti');
      can('route', 'UserDetails');
      can('route', 'SchoolDetails');
    } 
    if (user.role === 'user') {
      can('route', 'Home')
    }
    if (user.role === 'admin' || user.role === 'customerRep' || user.role === 'user') {
      can('route', 'Profile');
      can('route', 'About');
    }
  });
}
/*
import { AbilityBuilder } from "@casl/ability";
export default function defineAbilitiesFor(user) {
  if (user == null || user.role == null) {
    return null;
  }  
  return AbilityBuilder.define((can) => {
    const role = user.role;
    switch (role) {
      case 'admin':
        can('route', 'Users');
        can('route', 'UserDetails');
        can('route', 'Profile');
        break;
      case 'customeRep':
        can('route', 'Users');
        can('route', 'UserDetails');
        can('route', 'Profile');
        break;
      case 'user':
        can('route', 'Profile');
        break;
    }
  });
}*/