<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          to="/profile"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <span>{{$t('tooltip.profile')}}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          to="/about"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-help</v-icon>
        </v-btn>
      </template>
      <span>{{$t('tooltip.info')}}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          @click="logout()"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
      <span>{{$t('tooltip.logout')}}</span>
    </v-tooltip>
  </div>
</template>

<script>
import enums from "@/enums";
import { AuthService } from "@/services/auth.service";
import { mapMutations } from "vuex";

export default {
  name: 'Navigation',

  methods: {
    ...mapMutations("snackbar", ["showMessage","closeMessage"]),
    async logout(){
      await AuthService.logout();
      this.$router.push({ name: "Login" }).catch(()=>{});
    }
  },

  components: {  },

  computed: {
    enums() {
      return enums;
    }
  },

  data() {
    return {
    };
  },
};
</script>

<style scoped lang="sass">
.nav-button
  padding: 10px
</style>