var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-profile","fluid":"","tag":"section"}},[_c('MaterialCard',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [(_vm.mode == _vm.enums.FORM_MODE.UPDATE && _vm.loggedUser.id === Number(_vm.form.id)  && _vm.loggedUser.role != _vm.enums.ROLE_NAME.ADMIN)?_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")]):_c('div',[_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.formTitle.split(' ')[0] + ' ' + _vm.formTitle.split(' ')[1])+" ")]),_c('div',{staticClass:"subtitle-1 font-weight-light"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.formTitle.split(' ')[2]))+" ")])])]},proxy:true}])},[_c('v-form',[_c('v-container',{staticClass:"py-0"},[_c('v-card-subtitle',{directives:[{name:"show",rawName:"v-show",value:(_vm.loggedUser.id == Number(_vm.form.id) && _vm.loggedUser.role != _vm.enums.ROLE_NAME.ADMIN),expression:"loggedUser.id == Number(form.id) && loggedUser.role != enums.ROLE_NAME.ADMIN"}]},[_vm._v(" "+_vm._s(_vm.$t('reset_password.help_message'))+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('reset_password.help_message_1'))+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('reset_password.help_message_2'))+" ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',[(_vm.mode == _vm.enums.FORM_MODE.CREATE)?_c('div',[(_vm.role == _vm.enums.ROLE_NAME.USER)?_c('div',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Nome","immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Cognome","immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.surname')},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Codice Fiscale","immediate":"","rules":"required|min:16|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.cf')},model:{value:(_vm.form.cf),callback:function ($$v) {_vm.$set(_vm.form, "cf", $$v)},expression:"form.cf"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Email","immediate":"","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Telefono"}},[_c('v-text-field',{attrs:{"label":_vm.$t('headers.users.phone')},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],1)],1)],1):_vm._e(),(_vm.role == _vm.enums.ROLE_NAME.CUSTOMERREP)?_c('div',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Codice fiscale","immediate":"","rules":"required|min:11|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.cf')},model:{value:(_vm.form.cf),callback:function ($$v) {_vm.$set(_vm.form, "cf", $$v)},expression:"form.cf"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Denominazione","immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.school_name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Meccanografico","immediate":"","rules":"required|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.mechanographyc_code')},model:{value:(_vm.mechanographyc_code),callback:function ($$v) {_vm.mechanographyc_code=$$v},expression:"mechanographyc_code"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Username","immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.username'),"readonly":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Email","immediate":"","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.email'),"readonly":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Telefono"}},[_c('v-text-field',{attrs:{"label":_vm.$t('headers.users.phone')},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],1)],1)],1):_vm._e()]):_vm._e(),(_vm.mode == _vm.enums.FORM_MODE.UPDATE)?_c('div',[(_vm.loggedUser.role == _vm.enums.ROLE_NAME.CUSTOMERREP && _vm.loggedUser.id !== Number(_vm.form.id))?_c('div',[_c('ValidationProvider',{attrs:{"name":"Email","immediate":"","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):(_vm.loggedUser.role == _vm.enums.ROLE_NAME.ADMIN)?_c('div',[(_vm.role == _vm.enums.ROLE_NAME.USER)?_c('div',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Nome","immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Cognome","immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.surname')},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Codice Fiscale","immediate":"","rules":"required|min:16|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.cf'),"disabled":""},model:{value:(_vm.form.cf),callback:function ($$v) {_vm.$set(_vm.form, "cf", $$v)},expression:"form.cf"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Username"}},[_c('v-text-field',{attrs:{"label":_vm.$t('headers.users.username'),"disabled":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Email","immediate":"","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Telefono"}},[_c('v-text-field',{attrs:{"label":_vm.$t('headers.users.phone')},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Password","immediate":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('headers.users.password'),"type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Conferma Password","immediate":"","rules":{ equal: { firstValue: '@Password' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('headers.users.confirm_password'),"type":"password"},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.role == _vm.enums.ROLE_NAME.CUSTOMERREP)?_c('div',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Codice fiscale","immediate":"","rules":"required|min:11|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.cf'),"disabled":""},model:{value:(_vm.form.cf),callback:function ($$v) {_vm.$set(_vm.form, "cf", $$v)},expression:"form.cf"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Denominazione","immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.school_name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Meccanografico","immediate":"","rules":"required|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.mechanographyc_code')},model:{value:(_vm.mechanographyc_code),callback:function ($$v) {_vm.mechanographyc_code=$$v},expression:"mechanographyc_code"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Username"}},[_c('v-text-field',{attrs:{"label":_vm.$t('headers.users.username'),"disabled":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Email","immediate":"","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('headers.users.email'),"disabled":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Telefono"}},[_c('v-text-field',{attrs:{"label":_vm.$t('headers.users.phone')},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Password","immediate":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('headers.users.password'),"type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Conferma Password","immediate":"","rules":{ equal: { firstValue: '@Password' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('headers.users.confirm_password'),"type":"password"},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e()]):(_vm.loggedUser.id == Number(_vm.form.id))?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('headers.users.email'),"readonly":""},model:{value:(_vm.emailToShow),callback:function ($$v) {_vm.emailToShow=$$v},expression:"emailToShow"}})],1)],1),_c('v-row',{attrs:{"align":"right"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-vv-scope":"reset"},on:{"click":function($event){return _vm.submitToStore('reset')}}},'v-btn',attrs,false),on),[_c('font',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.$t('buttons.reset')))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.reset')))])])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":_vm.onCancel}},'v-btn',attrs,false),on),[_c('font',{attrs:{"color":"secondary"}},[_vm._v(_vm._s(_vm.$t('buttons.cancel')))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.cancel_reset')))])])],1)],1)],1):_vm._e()]):_vm._e()]),_c('FormButtons',{directives:[{name:"show",rawName:"v-show",value:(_vm.loggedUser.role == _vm.enums.ROLE_NAME.ADMIN || _vm.loggedUser.id != Number(_vm.form.id)),expression:"loggedUser.role == enums.ROLE_NAME.ADMIN || loggedUser.id != Number(form.id)"}],attrs:{"embedded":_vm.embedded,"disabled":invalid || _vm.form.password !== _vm.form.confirm_password || (_vm.loggedUser.role != _vm.enums.ROLE_NAME.ADMIN && _vm.loggedUser.id == Number(_vm.form.id))},on:{"onSave":_vm.onSubmit,"onCancel":_vm.onCancel}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }