<template>
  <v-container>    
    <MaterialCard>
      <template v-slot:heading>
        <div class="display-2 font-weight-light" align="center" justify="center">
          <v-row>
            <v-col align="right" style="margin-top:47px">
              {{ $t('login.message') }}
            </v-col>
            <v-col align="left">
              <img :src="require('../assets/logo_ese_netsecurity_tras.png')" width="140" />
            </v-col>
          </v-row>
        </div>
        <!--div>
          <div class="display-2 font-weight-light" align="center" justify="center">
            {{ $t('login.message') }}
          </div>
          <div class="subtitle-1 font-weight-light" align="center" justify="center">
            <v-row>
            <v-col>
              <img :src="require('../assets/ns-logo.png')" width="80" />
            </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>{{ $t('reset_password.message') }}</h3>
              </v-col>
            </v-row>
          </div>
        </div-->
      </template>

      <v-form>
        <v-container class="py-0">   
          <ValidationObserver v-slot="{ invalid }">
            <v-card-text >
              <ValidationProvider name="Codice Fiscale" rules='required|min:11|max:16' v-slot="{ errors }">
                <v-text-field
                  class="required"
                  :label="$t('reset_password.cf')"
                  v-model="cf"
                ></v-text-field>
                <span class="form-error">{{ errors[0] }}</span>
              </ValidationProvider>
              <!--ValidationProvider name="Username">
                <v-text-field
                  :label="$t('reset_password.username')"
                  v-model="username"
                ></v-text-field>
              </ValidationProvider-->
              <ValidationProvider name="Password" v-slot="{ errors }">
                <v-text-field
                  :label="$t('reset_password.password')"
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' : 'password'"
                  @keyup.enter.native="submit"
                ></v-text-field>
                <span class="form-error">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider name="Confirm Password" :rules="{ equal: { firstValue: '@Password' } }" v-slot="{ errors }">
                <v-text-field
                  :label="$t('reset_password.confirm_password')"
                  v-model="confirm_password"
                  :class="!password ? '' : 'required'"
                  :append-icon="showConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  @keyup.enter.native="submit"
                ></v-text-field>
                <span class="form-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </v-card-text>
            <div align="center" justify="center">
            <v-btn large :disabled="invalid" @click="submit" color="primary">
              {{$t('buttons.reset')}}
            </v-btn>
            </div>
          </ValidationObserver>
        </v-container>
      </v-form>
    </MaterialCard>
  </v-container>
</template>

<script>
import enums from "@/enums";
import { mapMutations } from "vuex";
import { AuthService } from "@/services/auth.service";
import MaterialCard from "@/components/base/MaterialCard"

export default {
  components: { MaterialCard },
  data: () => ({
    cf: "",
    //username: "",
    password: "",
    confirm_password: "",
    password_reset_token: "",
    showPassword: false,
    showConfirmPassword: false
  }),
  methods: {
    ...mapMutations("snackbar", ["showMessage","closeMessage"]),    
    async submit() {
      this.password_reset_token = this.$router.history.current.params.password_reset_token;
      
      const res=await AuthService.resetPassword(this.cf, this.password, this.confirm_password, this.password_reset_token);
      //const res=await AuthService.resetPassword(this.cf, this.username, this.password, this.confirm_password, this.password_reset_token);
      if (res.error) {
        this.showMessage({
          context: enums.TOAST_TYPE.ERROR,
          text: this.$t('toasts.reset_error')
        });
      }
      else {
        this.showMessage({
          context: enums.TOAST_TYPE.SUCCESS,
          text: this.$t('toasts.reset_ok')
        });
        this.$router.push({ name: "login" }).catch(()=>{})
      }
    },
  },
  computed: {
    enums() {
      return enums;
    }
  }
}
</script>

<style scoped>
.container {
  margin-top: 50px;
  width: 50%;
}
</style>