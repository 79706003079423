import { TokenService } from "./token.service";
import ApiService from "./api.service";
import store from "@/store";

async function login(username, password) {
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
    data: {
      username: username,
      password: password
    }
  };
  try {
    const response = await ApiService.customRequest(requestData);
    TokenService.saveToken(response.data);
    //ApiService.setHeader();
    store.commit("status/setLogged", true);
    return response.data;
  } catch (error) {
    return {error};
  }
}

async function confirmUser(cf, username, password, confirm_password, activation_token) {
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    url: `${process.env.VUE_APP_ENDPOINT_API_URI}/public/users/activate_profile`,
    data: {
      cf: cf,
      username: username,
      password: password,
      confirm_password: confirm_password,
      activation_token: activation_token
    }
  };
  try {
    const response = await ApiService.customRequest(requestData);
    return response.data;
  } catch (error) {
    return {error};
  }
}

async function forgotPassword(username){
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    url: `${process.env.VUE_APP_ENDPOINT_API_URI}/public/users/forgot_password`,
    data: {
      username: username
    }    
  };
  try {
    const response = await ApiService.customRequest(requestData);
    return response.data;
  } catch (error) {
    return {error};
  }
}

async function resetPassword(cf, password, confirm_password, password_reset_token) {
//async function resetPassword(cf, username, password, confirm_password, password_reset_token) {
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    url: `${process.env.VUE_APP_ENDPOINT_API_URI}/public/users/reset_password`,
    data: {
      cf: cf,
      password: password,
      confirm_password: confirm_password,
      password_reset_token: password_reset_token
    }
  };
  try {
    const response = await ApiService.customRequest(requestData);
    return response.data;
  } catch (error) {
    return {error};
  }
}

function logout() {
  TokenService.removeToken();
  ApiService.removeHeader();
  store.commit("status/setLogged", false);
}

export const AuthService = {
  login,
  confirmUser,
  forgotPassword,
  resetPassword,
  logout
};
