import Vue from "vue";
import ApiService from "@/services/api.service";
import { TokenService } from "@/services/token.service";

const DevourService = {
  localToJsonApiPagination(paginationOpts,search,filter) {
    let _sort=paginationOpts.sortBy[0];
    if (!paginationOpts.sortDesc[0])
      _sort=`-${_sort}`;

    return {
      page: {
        page: paginationOpts.page,
        pageSize: paginationOpts.itemsPerPage
      },
      sort: _sort,
      search: search?search:"",
      filter: filter?filter:""
    };
  },
  async fetchOne(type,include,id) {
    try {
      let res = await Vue.$jsonApi.find(type,id,{
        include: include
      });
      if (res && res.error) {
        return {error: res.error};
      }
      return res.data;
    } catch (error) {
      return { error };
    }
  },
  async fetchAll(type,include,paginationOpts,search,filter) {
    let searchFilterPagination=this.localToJsonApiPagination(paginationOpts,search,filter);
    searchFilterPagination.include = include;
    try {
      let res = await Vue.$jsonApi.findAll(type,searchFilterPagination);
      if (res && res.error) {
        return {error: res.error};
      }
      return [res.data,res.meta.pagination.rowCount];
    } catch (error) {
      return { error };
    }
  },
  async createOrUpdate(type,payload) {
    try {
      let res;
      if (payload.id == "" || !payload.id) {
        res=await Vue.$jsonApi.create(type, payload);
      }
      else
        res=await Vue.$jsonApi.update(type, payload);
      if (res && res.error) {
        return {error: res.error};
      }
      return res;
    } catch (error) {
      return { error };
    }
  },
  async delete(type,id) {
    if (id == null)
      return null;
    try {
      let res = await Vue.$jsonApi.destroy(type, id);
      if (res && res.error) {
        return {error: res.error};
      }
      return res;
    } catch (error) {
      return { error };
    }
  },
  async upload(payload,role){
    let token = TokenService.getToken();
    const requestData = {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data" 
      },
      url: `${process.env.VUE_APP_ENDPOINT_API_URI}/private/users/upload_file/${role}`,
      data: payload
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      return {error};
    }
  }
};

export { DevourService };
