import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import UserAuthentication from '../views/UserAuthentication.vue'
import ResetPassword from '../views/ResetPassword.vue'
import UsersList from '../views/UsersList.vue'
import UserDetails from '../views/UserDetails.vue'
import Profile from '../views/Profile.vue'
import Restricted from '../views/Restricted.vue'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Settings from '../views/Settings.vue'
import defineAbilitiesFor from '@/abilities'
import store from "@/store";
import ApiService from "@/services/api.service";
import i18n from "@/i18n";
import { TokenService } from '@/services/token.service'

Vue.use(VueRouter)

const routes = [  
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/restricted',
    name: 'Restricted',
    component: Restricted
  },
  {
    path: '/activate_profile/:user_role/:activation_token',
    name: 'UserAuthentication',
    component: UserAuthentication
  },
  {
    path: '/reset_password/:password_reset_token',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersList,
    props: { userType: 'user' }
  },
  {
    path: '/schools',
    name: 'Scuole',
    component: UsersList,
    props: { userType: 'customerRep' }
  },
  {
    path: '/users/:id',
    name: 'UserDetails',
    component: UserDetails,
    props: true
  },
  {
    path: '/schools/:id',
    name: 'SchoolDetails',
    component: UserDetails,
    props: true
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  }
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!localStorage.locale) localStorage.locale = "it";
  i18n.locale = localStorage.locale;

  const loggedIn = localStorage.getItem("jwt");

  if (loggedIn) ApiService.setHeader();
  if (loggedIn) store.commit("status/setLogged", true);

  router.app.$ability(defineAbilitiesFor(TokenService.getPayload()));

  const publicPages = ["Login","Restricted","UserAuthentication","ResetPassword"];
  const authRequired = !publicPages.includes(to.name);

  if (!authRequired) {
    next();
  } else if (!loggedIn) {
    return next({ name: "Login" });
  } else if (!router.app.$can("route", to.name)) {
    next("/restricted");
  } else {
    next();
  }
});

export default router
