var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('MaterialCard',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light",attrs:{"align":"center","justify":"center"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"47px"},attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.$t('login.message'))+" ")]),_c('v-col',{attrs:{"align":"left"}},[_c('img',{attrs:{"src":require('../assets/logo_ese_netsecurity_tras.png'),"width":"140"}})])],1)],1)]},proxy:true}])},[_c('v-form',[_c('v-container',{staticClass:"py-0"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Codice Fiscale","rules":"required|min:11|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('reset_password.cf')},model:{value:(_vm.cf),callback:function ($$v) {_vm.cf=$$v},expression:"cf"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('reset_password.password'),"append-icon":_vm.showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline',"type":_vm.showPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit($event)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":{ equal: { firstValue: '@Password' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:!_vm.password ? '' : 'required',attrs:{"label":_vm.$t('reset_password.confirm_password'),"append-icon":_vm.showConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline',"type":_vm.showConfirmPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit($event)}},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"large":"","disabled":invalid,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('buttons.reset'))+" ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }