<template>
<div class="my-container">
    <v-card>
        <Toolbar
            :withBack="true"
            @onBack="goTo()"
        />
        <v-row align="center">
            <v-col>
                <span class="align-middle text-h2">Impostazioni del sistema</span>          
            </v-col>
        </v-row>        
        <v-row align="center">
            <v-col>
                <span class="align-middle text-h4"> Questa pagina è in fase di elaborazione</span>          
            </v-col>
        </v-row>
    </v-card>
</div>
</template>

<script>
import enums from "@/enums";
import helper from "@/mixins/helper";
import Toolbar from "@/components/Toolbar";
import { TokenService } from "@/services/token.service";

export default {
  name: 'Settings',

  components: {
    Toolbar
  },

  mixins: [helper],

  methods: {
    goTo() {
        if (this.loggedUser.role == enums.ROLE_NAME.USER) {
            this.$router.push({ path: 'home' });
        } else {
            this.$router.push({ path: 'users' });
        }
    }
  },

  computed: {
    enums() {
      return enums;
    },
    loggedUser() {
      return TokenService.getPayload();
    }
  },

}
</script>