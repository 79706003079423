<template>
  <div>
    <UsersFilter
      ref="drawer"
      @drawerChanged="handleDrawer"
      @onChange="handleChange"
    ></UsersFilter>
    <div class="my-container">
      <v-card :class="{ 'center-panel': drawer_flag }">
        <v-container fluid class="my-toolbar" v-if="loggedUser.role==enums.ROLE_NAME.CUSTOMERREP">
          <v-row align="center">
            <v-col class="align-left">
              <h4 class="display-2 font-weight-light mb-3 black--text">
                {{ this.profile.name }}
              </h4>    
              <h6 class="align-middle text-h4">
                {{ this.profile.mechanographyc_code }}
              </h6>
            </v-col>
          </v-row>
        </v-container>
        <Toolbar
          :withMenu="true"
          :withUpload="loggedUser.role!==enums.ROLE_NAME.USER"
          :userRole="userType"
          :withFilter="false"
          :withSearch="loggedUser.role!==enums.ROLE_NAME.USER"
          :searchLabel="loggedUser.role===enums.ROLE_NAME.CUSTOMERREP ? 'Ricerca docente tramite codice fiscale' : 'Ricerca'"
          :withAdd1="loggedUser.role!==enums.ROLE_NAME.USER && userType==enums.ROLE_NAME.USER"
          :withAdd2="loggedUser.role!==enums.ROLE_NAME.CUSTOMERREP && loggedUser.role!==enums.ROLE_NAME.USER && userType==enums.ROLE_NAME.CUSTOMERREP"
          :allClear="allClear"
          @onUpload="openUpload"
          @onFilter="$refs.drawer.open()"
          @onSearch="handleSearch"
          @onAdd="openCreate"
        />
        <div v-if="this.loggedUser.role!==enums.ROLE_NAME.CUSTOMERREP || (this.loggedUser.role===enums.ROLE_NAME.CUSTOMERREP && total !== 0)">
          <BaseGrid          
            :tableName="this.$t('navigation.'+userType)"
            :headers="tableData.headers"
            :items="tableData.items"
            :totalLength="total"
            :injectOpts="paginationOpts"
            :loading="loading>0"
            :withActions="true"
            :withEdit="true"
            :withDelete="this.loggedUser.role==enums.ROLE_NAME.ADMIN"
            @onPaginationChanged="handlePaginationChanged"
            @onEdit="openUpdate"
            @onDelete="handleDelete"
          ></BaseGrid>
        </div>
        <v-dialog
          v-model="formDialog"
          content-class="edit-form-dialog"
        >
          <v-card>
            <UserForm
              v-if="formDialog"
              :mode="mode"
              :role="userType"
              :selectedItem="editItem"
              @formSucceed="fetch()"
              @formClose="close()"
            />
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="formUpload"
          content-class="edit-form-dialog"
        >
          <v-card>            
          <UploadFileForm
            v-if="formUpload"
            :role="userType"
            @formSucceed="fetch()"
            @formClose="close()"
          ></UploadFileForm>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
import Toolbar from "@/components/Toolbar";
import BaseGrid from "@/components/BaseGrid";
import _ from "lodash";
import helper from "@/mixins/helper";
import formDialog from "@/mixins/formDialog";
import listShared from "@/mixins/listShared";
import UserForm from "@/components/forms/UserForm";
import UsersFilter from "@/components/filters/UsersFilter";
import UploadFileForm from "@/components/forms/UploadFileForm";
import { DevourService } from "@/services/devour.service";
import { TokenService } from "@/services/token.service";
import enums from "@/enums";

export default {
  name: 'UsersList',

  components: {
    BaseGrid,
    UserForm,
    Toolbar,
    UsersFilter,
    UploadFileForm
  },

  mixins: [helper,formDialog,listShared],
  props: {
    userType: {
      default: 'user',
      type: String
    }
  },
  watch: {
    userType(){
      this.fetch();
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    }
  },
  computed: {
    enums() {
      return enums;
    },
    loggedUser() {
      return TokenService.getPayload();
    }
  },

  methods: {
    mapHeaders() {
      let tableHeaders=[];
      tableHeaders.push({
        value: "name",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        value: "cf",
        align: "start",
        sortable: true
      }); 
      if (this.$props.userType==enums.ROLE_NAME.CUSTOMERREP) {
        tableHeaders.push({
          value: "mechanographyc_code",
          align: "start",
          sortable: true
        });
      }
      tableHeaders.push({
        value: "email",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        value: "phone",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        value: "username",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        value: "enabled",
        align: "start",
        sortable: true
      });
      return tableHeaders;
    },
    mapItems() {
      let tableItems = _.map(this.items, item => {
        item.fields = {
          name: { data: item.surname ?  item.name + " " + item.surname : item.name, dataType: "text" },
          cf: { data: item.cf, dataType: "text" },
        }
        if (this.$props.userType==enums.ROLE_NAME.CUSTOMERREP) item.fields.mechanographyc_code = { data: item.mechanographyc_code, dataType: "text" };
        //item.fields = {
          item.fields.email = { data: item.email, dataType: "text" },
          item.fields.phone = { data: item.phone, dataType: "text" },
          item.fields.username = { data: item.username, dataType: "text" },
          item.fields.enabled = { data: item.enabled ? this.$t('confirm.yes') : this.$t('confirm.no'), dataType: "text" }
        //};
        item.click_action = {
          actionType: "router-link",
          namedRoot: this.$props.userType===enums.ROLE_NAME.CUSTOMERREP ? "SchoolDetails" : "UserDetails",
          namedRootId: item.id,
          icon: "gps_fixed"
        };
        item.actions = {
        }
        return item;
      });
      return tableItems;
    },
    deleteFunc(item) {
      return this.deleteConfirm(
        this.resourceType,
        "user",
        item,
        payload => payload.p.id
      );
    },
    fetchFunc(paginationOpts=null,search,filter) {
      if(this.$props.userType==enums.ROLE_NAME.CUSTOMERREP) {
        filter = {
          role_id: 2
        }
      } 
      if(this.$props.userType==enums.ROLE_NAME.USER) {
        filter = {
          role_id: 3
        }
      }
      return DevourService.fetchAll("users","role",paginationOpts,search,filter);
    },    
    async getUserProfile(){
      const devourRes = await DevourService.fetchOne("users","role",this.loggedUser.id);
      this.profile = devourRes;
    }
  },

  data() {
    return {
      resourceType: this.$t("resource_types.user"),
      resourceTypes: this.$t("resource_types.users"),
      injectOpts: {
        sortBy: ["name"],
        sortDesc: [true],
      },
      profile: {}
    };
  },

  created() {
    this.getUserProfile();
  }
};
</script>
