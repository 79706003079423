<template>
  <v-container>
    <MaterialCard>
        <template v-slot:heading>
          <div class="display-2 font-weight-light" align="center" justify="center">
            <v-row>
              <v-col align="right" style="margin-top:47px">
                {{ $t('login.message') }}
              </v-col>
              <v-col align="left">
                <img :src="require('../assets/logo_ese_netsecurity_tras.png')" width="140" />
              </v-col>
            </v-row>
          </div>
        </template>

        <v-form>
          <v-container class="py-0">   
            <ValidationObserver v-slot="{ invalid }">
              <v-card-text >
                <ValidationProvider name="Username" rules="required" v-slot="{ errors }">
                  <v-text-field
                    class="required"
                    :label="$t('login.username')"
                    v-model="username"
                  ></v-text-field>
                  <span class="form-error">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                  <v-text-field
                    :label="$t('login.password')"
                    v-model="password"
                     class="required"
                    :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    @click:append="showPassword = !showPassword"
                    :type="showPassword ? 'text' : 'password'"
                    @keyup.enter.native="submit"
                  ></v-text-field>
                  <span class="form-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-card-text>
              <div align="center" justify="center">
              <v-btn large :disabled="invalid" @click="submit" color="primary">
                {{$t('buttons.login')}}
              </v-btn>
              <v-btn text :disabled="username == ''" @click="forgot" color="primary">
                {{$t('buttons.forgot_password')}}
              </v-btn>
              </div>
            </ValidationObserver>
          </v-container>
        </v-form>
    </MaterialCard>
  </v-container>
</template>

<script>
import enums from "@/enums";
import { mapMutations } from "vuex";
import { AuthService } from "@/services/auth.service";
import { TokenService } from "@/services/token.service";
import MaterialCard from "@/components/base/MaterialCard";
import defineAbilityFor from './../abilities';

export default {
  components: { MaterialCard },
  data: () => ({
    username: "",
    password: "",
    showPassword: false,
  }),
  methods: {
    ...mapMutations("snackbar", ["showMessage","closeMessage"]),
    goToNext() {
      const startPages = ["Users", "Home"];

      let found = false;
      for (const r of startPages) {
        const ability = defineAbilityFor(TokenService.getPayload());
        if (ability.can("route", r)) {
          this.$router.push({ name: r }).catch(()=>{});
          found = true;
          break;
        }
      }
      if (!found) this.$router.push({ name: "default" }).catch(()=>{});
    },
    async submit() {
      const res=await AuthService.login(this.username, this.password);
      if (res.error) {
        this.showMessage({
          context: enums.TOAST_TYPE.ERROR,
          text: this.$t('toasts.login_failed')
        });
      }
      else {
        this.goToNext();
      }
    },
    async forgot() {
      const res=await AuthService.forgotPassword(this.username);
      if (res.error) {
        this.showMessage({
          context: enums.TOAST_TYPE.ERROR,
          text: this.$t('toasts.forgot_error')
        });
      }
      else {
        this.showMessage({
          context: enums.TOAST_TYPE.SUCCESS,
          text: this.$t('toasts.forgot_ok')
        });
        this.goToNext();
      }
    }
  },
  computed: {
    enums() {
      return enums;
    }
  }
}
</script>

<style scoped>
.container {
  margin-top: 50px;
  width: 50%;
}
/*
.container {
  background: #7389ae;
  width: 50%;
}*/
</style>