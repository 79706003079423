import Vue from 'vue'
import Vuex from 'vuex'

import snackbar from "./modules/snackbar";
import status from "./modules/status";
import filters from "./modules/filters";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: null,
  },
  mutations: {
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
  },
  actions: {
  },
  modules: {
    snackbar,
    status,
    filters
  }
})
