<template>
  <v-container fluid>
    <MaterialCard>
      <template v-slot:heading>
        <div>
          <div class="display-2 font-weight-light">
            {{ $t("misc.upload_file") | capitalize }} {{ role=='user' ? "Docenti" : "Scuole" }}
          </div>
        </div>
      </template>
      <v-form class="form-body" @submit.prevent="onSubmit">
        <v-row>
          <v-col cols="9" class="form-group required">
          <v-file-input
              id="file"
              ref="file"
              :accept="acceptedFormats"
              clearable
              @change="handleFileUpload"
              :label="$t('misc.file')"
              name="file"
          ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="align-left" v-if="role=='user'">
          <a href="./../../../template_docenti.csv" download="template_docenti.csv">{{$t('tooltip.download')}}</a>
          </v-col>
          <v-col cols="3" class="align-left" v-if="role=='customerRep'">
          <a href="./../../../template_scuole.csv" download="template_scuole.csv">{{$t('tooltip.download')}}</a>
          </v-col>
          <v-col>
        <!-- BUTTON -->
        <FormButtons
          :withUpload="true"
          @onSave="onSubmit"
          @onCancel="onCancel"
        />
          </v-col>
        </v-row>
      </v-form>
    </MaterialCard>
  </v-container>
</template>

<script>
import Enums from "@/enums";
import FormButtons from "@/components/FormButtons";
import MaterialCard from "@/components/base/MaterialCard";
import formShared from "@/mixins/formShared";
import helper from "@/mixins/helper";

export default {
  components: {
    FormButtons,
    MaterialCard
  },
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object,
    role: String
  },
  data() {
    return {
      progress: true,
      resourceType: this.$t("resource_types.users"),
      form: {
        id: "",
        file: ""
      },
      acceptedFormats: ".csv",
    };
  },

  mixins: [Enums,formShared,helper],
  methods: {
    async submitToStore() {
      console.log("this.$props.role: ", this.$props.role)
      let formData = new FormData();
      formData.append('file', this.form.file);
      await this.uploadHelper(
        formData,
        this.$props.role
      );
    },
    setForm() {
      this.form.id = "";
      this.form.file = "";
    },
    handleFileUpload(e){
      this.form.file = e;
    },
    initForm() {
      this.setForm();
    },
    downloadTemplate() {
      const link = document.createElement("a");
      link.href = '../../../public/template_docenti.csv';
      link.download = 'template_docenti.csv';
      link.click();
    }
  },
  computed: {
    Enums() {
      return Enums;
    }
  },
  created() {
    this.initForm();
  }
};
</script>

<style scoped>
.form-body {
  margin-top: 30px;
}
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
