<template>
  <div class="my-container">
    <v-card>
      <Toolbar
        :title="resourceType | capitalize"
        :withBack="true"
        :withEdit="true"
        :withDelete="loggedUser.role==enums.ROLE_NAME.ADMIN && !this.selectedItem.enabled"
        @onEdit="openUpdate"
        @onDelete="handleDelete"
        :userRole="this.selectedItem.role.name"
      ></Toolbar>

      <v-tabs
        v-model="activeTab"
        @change="syncUrl"
        background-color="secondary"
        slider-color="accent"
        dark
      >
        <v-tab key="details">{{ $t("details.users.details") }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item key="details">
          <div class="my-container">
            <v-row>
              <v-col cols="4" class="body-1 font-weight-light nowrap">{{
                $t('headers.users.username') | upperCase
              }}</v-col>
              <v-col cols="5" class="body-1">{{
                this.selectedItem.username || "-"
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="body-1 font-weight-light nowrap">{{
                $t('headers.users.name') | upperCase
              }}</v-col>
              <v-col cols="5" class="body-1">{{
                this.selectedItem.name || "-"
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="body-1 font-weight-light nowrap">{{
                $t('headers.users.surname') | upperCase
              }}</v-col>
              <v-col cols="5" class="body-1">{{
                this.selectedItem.surname || "-"
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="body-1 font-weight-light nowrap">{{
                $t('headers.users.cf') | upperCase
              }}</v-col>
              <v-col cols="5" class="body-1">{{
                this.selectedItem.cf || "-"
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="body-1 font-weight-light nowrap">{{
                $t('headers.users.email') | upperCase
              }}</v-col>
              <v-col cols="5" class="body-1">{{
                this.selectedItem.email || "-"
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="body-1 font-weight-light nowrap">{{
                $t('headers.users.phone') | upperCase
              }}</v-col>
              <v-col cols="5" class="body-1">{{
                this.selectedItem.phone || "-"
              }}</v-col>
            </v-row>
            <v-row v-if="loggedUser.role==enums.ROLE_NAME.ADMIN">
              <v-col cols="4" class="body-1 font-weight-light nowrap">{{
                $t('headers.users.role') | upperCase
              }}</v-col>
              <v-col cols="5" class="body-1">{{
                this.selectedItem.role ? $t('role_type.'+this.selectedItem.role.name) : "-"
              }}</v-col>
            </v-row>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <v-dialog
        v-model="formDialog"
        content-class="edit-form-dialog"
      >
        <v-card>
          <UserForm
            v-if="formDialog"
            :mode="mode"
            :role="this.selectedItem.role.name"
            :selectedItem="this.selectedItem"
            @formSucceed="fetch()"
            @formClose="close()"
          />
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import enums from "@/enums";
import helper from "@/mixins/helper";
import UserForm from "@/components/forms/UserForm";
import Toolbar from "@/components/Toolbar";
import formDialog from "@/mixins/formDialog";
import detailsShared from "@/mixins/detailsShared";
import { DevourService } from "@/services/devour.service";
import { TokenService } from "@/services/token.service";
import { mapMutations } from "vuex";

export default {
  name: "UserDetails",
  data() {
    return {
      home: "Users",
      resourceType: this.$t("resource_types.user")
    };
  },
  methods: {
    ...mapMutations("filters", ["setUsersFlag"]),
    
    fetchFunc(id) {
      return DevourService.fetchOne("users","role",id);
    },    
    title(item) {
      return this.makeTitleDetails(this.resourceType,item.username);
    },
    deleteFunc(item) {
      return this.deleteConfirm(
        this.resourceType,
        "user",
        item,
        payload => payload.p.id
      );
    }
  },
  created() {
    this.setUsersFlag(true);
  },
  components: {
    UserForm,
    Toolbar,
  },
  mixins: [helper,formDialog,detailsShared],
  computed: {
    enums() {
      return enums;
    },
    loggedUser() {
      return TokenService.getPayload();
    }
  },
};
</script>
