var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":"","to":"/profile"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.profile')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":"","to":"/about"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.info')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""},on:{"click":function($event){return _vm.logout()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.logout')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }